import { Icon } from '@nrk/yr-icons';
import classNames from 'classnames';
import { useLocationList } from '../../contexts/LocationListContext/LocationListContext';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ClickableButton } from '../Clickable/Clickable';

interface IProps {
  locationId: string;
  locationName: string;
  isFavourite: boolean;
}

export const WeatherLocationListItem__Star = (props: IProps) => {
  const { locationId, locationName, isFavourite } = props;

  const translate = useTranslate();
  const { addFavouritedLocation, removeFavouritedLocation } = useLocationList();

  function onStarClick(event: React.MouseEvent<HTMLButtonElement>) {
    // Prevent link from opening when clicking the favourite button
    event.preventDefault();
    if (isFavourite) {
      removeFavouritedLocation({
        locationId,
        locationName,
        translate
      });
    } else {
      addFavouritedLocation({
        locationId,
        locationName,
        translate
      });
    }
  }

  const iconId = isFavourite ? 'icon-star-filled' : 'icon-star';

  return (
    <ClickableButton
      className={classNames('weather-location-list-item__star', {
        'weather-location-list-item__star--filled': isFavourite
      })}
      aria-label={isFavourite ? translate('nav/favouritesButtonActiveLabel') : translate('nav/favouritesButtonLabel')}
      onClick={onStarClick}
    >
      <Icon id={iconId}></Icon>
    </ClickableButton>
  );
};
