import { Heading } from '../Heading/Heading';

interface IProps {
  hidden?: boolean;
  children: React.ReactNode;
  marginBottom?: 1 | 2 | 3;
  marginTop?: 1 | 2 | 3;
}
export const ContentHeading = (props: IProps) => {
  const { children, hidden, marginBottom, marginTop } = props;

  return (
    // We use tabIndex="-1" here because the content-heading is automatically focused when navigating to a new page, if it exists
    // See the function focusPage wich is called from Root.tsx
    <Heading
      level="h1"
      size="1"
      id="content-heading"
      data-testid="content-heading"
      tabIndex={-1}
      hidden={hidden}
      marginBottom={marginBottom}
      marginTop={marginTop}
    >
      {children}
    </Heading>
  );
};
