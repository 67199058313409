import { Icon, TIconId } from '@nrk/yr-icons';
import { Text } from '../Text/Text';
import './ColorButton.scss';

interface IProps {
  tone: 'blue' | 'red' | 'green' | 'green-alt' | 'purple' | 'pink' | 'violet';
  iconLeft?: TIconId;
  iconRight?: TIconId;
  children: React.ReactNode;
  href: string;
  isExternal?: boolean;
}

export function ColorButton(props: IProps) {
  const { iconLeft, iconRight, tone, children, href, isExternal, ...htmlProps } = props;

  const externalLinkProps = isExternal ? { target: '_blank', rel: 'noopener noreferrer' } : undefined;

  return (
    <a className="color-button" data-tone={tone} href={href} {...htmlProps} {...externalLinkProps}>
      {iconLeft && <Icon className="color-button__item-icon-left" id={iconLeft} size={3} />}
      <Text className="color-button__item-text" size={'4'} weight="bold" color="inherit">
        {children}
      </Text>
      {iconRight && <Icon className="color-button__item-icon-right" id={iconRight} size={3} />}
    </a>
  );
}
