import { IApiArticle } from '../../model/api/articles';
import { Heading } from '../Heading/Heading';
import { Text } from '../Text/Text';
import './ArticleList.scss';

interface IProps {
  articles: IApiArticle[];
}

export function ArticleList(props: IProps) {
  const { articles } = props;

  return (
    <div className="article-list">
      {articles.map((article, index) => {
        // TODO(as): Do not render article if data is missing
        // This was added to prevent the HomePage from crashing if data is missing for some reason
        if (article.image == null || article.link == null || article.heading == null) {
          return null;
        }

        // The image-size is roughly the same on all screen-sizes, so we use resolution-switching
        // to provide sizes that fit for screens with different pixelRate per "real-world-pixel"
        const srcSet = `${article.image.sources[1].url} 1x, 
                          ${article.image.sources[2].url} 1.5x, 
                          ${article.image.sources[3].url} 3x`;

        return (
          <article key={article.link} data-testid={`article-${index}`} className="article-list__article">
            <img
              alt={article.image.description}
              className="article-list__image"
              src={article.image.sources[2].url}
              srcSet={srcSet}
              // Give the image a fixed width and height to prevent layout shift.
              // The exact image source might change because of the "srcset" attribute,
              // but they all have the same aspect ratio so we just use the dimensions
              // from the fallback image we use in the "src" attribute.
              width={article.image.sources[2].width}
              height={article.image.sources[2].height}
              loading="lazy"
            />

            <div className="article-list__caption">
              <Heading level="h3" size="3" className="article-list__heading">
                <a className="article-list__link" href={article.link}>
                  {article.heading}
                </a>
              </Heading>
              {article.preface && (
                <Text size="4" weight="normal" as="p">
                  {article.preface}
                </Text>
              )}
            </div>
          </article>
        );
      })}
    </div>
  );
}
