import { useTranslate } from '../../lib/hooks/useTranslate';
import { FeaturedContentButton } from '../FeaturedContentButton/FeaturedContentButton';
import { Heading } from '../Heading/Heading';
import './ExternalServices.scss';

interface Item {
  text: string;
  href: string;
}

interface IProps {
  title?: string;
  items: [Item] | [Item, Item] | [Item, Item, Item] | [Item, Item, Item, Item];
}

export function ExternalServices(props: IProps) {
  const { title, items } = props;

  const translate = useTranslate();

  return (
    <div className="external-services">
      <Heading level="h2" size="2" marginBottom={2}>
        {title ?? translate('externalServices/defaultTitle')}
      </Heading>

      <div className="external-services__items">
        {items.map(item => (
          <FeaturedContentButton type="external" text={item.text} url={item.href} key={item.text} />
        ))}
      </div>
    </div>
  );
}
