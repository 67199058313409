import { TIconId } from '@nrk/yr-icons';
import { createPageUrl } from '../../app/redirects';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useMapSubpageId } from '../../lib/hooks/useMapSubpageId';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ColorButton } from '../ColorButton/ColorButton';

type TBigButtonType = 'map' | 'weather-warnings' | 'mountain-passes' | 'water-temperatures' | 'snow-depths';

interface IInternalProps {
  type: TBigButtonType;
}

interface IExternalProps {
  type: 'external';
  url: string;
  text?: string;
}

interface IBigButtonItem {
  icon: TIconId;
  localeKey: string;
  tone: 'blue' | 'red' | 'green' | 'green-alt' | 'purple' | 'pink' | 'violet';
  url: {
    pageId: string;
    subpageId?: string;
  };
}

export function FeaturedContentButton(props: IInternalProps | IExternalProps) {
  const { type, ...htmlProps } = props;
  const translate = useTranslate();
  const mapSubpageId = useMapSubpageId();

  const localeCode = useLocaleCode();

  const BIG_BUTTON_TYPES: { [key in TBigButtonType]: IBigButtonItem } = {
    map: {
      icon: 'icon-map',
      localeKey: 'featuredContent/items/map',
      tone: 'purple',
      url: {
        pageId: 'map',
        subpageId: mapSubpageId
      }
    },
    'weather-warnings': {
      icon: 'icon-warning',
      localeKey: 'featuredContent/items/weatherWarnings',
      tone: 'red',
      url: {
        pageId: 'weather-warnings'
      }
    },
    'water-temperatures': {
      icon: 'icon-temperature',
      localeKey: 'featuredContent/items/waterTemperatures',
      tone: 'blue',
      url: {
        pageId: 'water-temperatures-region-list'
      }
    },
    'mountain-passes': {
      icon: 'icon-mountain',
      localeKey: 'featuredContent/items/mountainPasses',
      tone: 'green',
      url: {
        pageId: 'mountain-passes',
        subpageId: 'south'
      }
    },
    'snow-depths': {
      icon: 'icon-snow',
      localeKey: 'featuredContent/items/snowDepths',
      tone: 'green-alt',
      url: {
        pageId: 'snow-depth-region-list'
      }
    }
  };

  if (type === 'external') {
    const { url, text } = props;
    return (
      <ColorButton tone={'blue'} href={url} iconRight="icon-new-window" isExternal={true} {...htmlProps}>
        {text}
      </ColorButton>
    );
  }

  const buttonContent = BIG_BUTTON_TYPES[type];

  return (
    <ColorButton
      tone={buttonContent.tone}
      href={createPageUrl({ localeCode, ...buttonContent.url })}
      iconLeft={buttonContent.icon}
      {...htmlProps}
    >
      {translate(buttonContent.localeKey)}
    </ColorButton>
  );
}
